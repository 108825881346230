import React, { createContext, useContext, useState, useEffect } from 'react';
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../lib/firebase';
import { signUp, signIn, resetPassword } from '../lib/auth-utils';
import { handleLogout } from '../lib/auth/logout';

type AuthContextType = {
  currentUser: User | null;
  loading: boolean;
  error: Error | null;
  signUp: (email: string, password: string, name: string) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  logOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  clearError: () => void;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const clearError = () => setError(null);

  const value = {
    currentUser,
    loading,
    error,
    clearError,
    signUp: async (email: string, password: string, name: string) => {
      try {
        setError(null);
        await signUp(email, password, name);
      } catch (err) {
        setError(err as Error);
        throw err;
      }
    },
    signIn: async (email: string, password: string) => {
      try {
        setError(null);
        await signIn(email, password);
      } catch (err) {
        setError(err as Error);
        throw err;
      }
    },
    logOut: async () => {
      try {
        setError(null);
        await handleLogout();
        setCurrentUser(null);
      } catch (err) {
        setError(err as Error);
        throw err;
      }
    },
    resetPassword: async (email: string) => {
      try {
        setError(null);
        await resetPassword(email);
      } catch (err) {
        setError(err as Error);
        throw err;
      }
    }
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}