import React, { createContext, useContext, useState, useEffect } from 'react';
import { useArtist } from '../hooks/useArtist';
import { Artist } from '../types';
import { useAuth } from './AuthContext';

type ProfileContextType = {
  profile: Artist | null;
  updateProfile: (data: Partial<Artist>) => Promise<void>;
  loading: boolean;
  error: Error | null;
};

const ProfileContext = createContext<ProfileContextType>({} as ProfileContextType);

export function useProfile() {
  return useContext(ProfileContext);
}

export function ProfileProvider({ children }: { children: React.ReactNode }) {
  const { artist, loading: artistLoading, error, updateArtist, createArtist } = useArtist();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<Artist | null>(null);

  // Clear profile when user changes or logs out
  useEffect(() => {
    const handleClearData = () => {
      setProfile(null);
    };

    window.addEventListener('clearUserData', handleClearData);
    return () => {
      window.removeEventListener('clearUserData', handleClearData);
    };
  }, []);

  // Initialize or update profile when user/artist changes
  useEffect(() => {
    const initializeProfile = async () => {
      if (!currentUser) {
        setProfile(null);
        setLoading(false);
        return;
      }

      try {
        if (!artist && !artistLoading) {
          // Create new artist profile
          const newArtist = await createArtist({
            name: currentUser.displayName || 'Artist Name',
            email: currentUser.email || '',
            bio: '',
            currency: { code: 'USD', symbol: '$' },
            storageLimit: 500 * 1024 * 1024, // 500MB base storage
            storageBonus: 0,
            referralCode: `REF${Math.random().toString(36).substr(2, 8).toUpperCase()}`,
            createdAt: new Date().toISOString(),
          });
          setProfile(newArtist);
        } else if (artist) {
          setProfile(artist);
        }
      } catch (err) {
        if (currentUser) {
          console.error('Error initializing profile:', err);
        }
      } finally {
        setLoading(false);
      }
    };

    initializeProfile();
  }, [artist, artistLoading, createArtist, currentUser]);

  // Don't render children until we have either loaded the profile or determined there isn't one
  if (loading) {
    return null;
  }

  return (
    <ProfileContext.Provider 
      value={{ 
        profile, 
        updateProfile: updateArtist,
        loading: artistLoading,
        error
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}