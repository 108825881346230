import { signOut } from 'firebase/auth';
import { auth } from '../firebase';

// Clear all application state and storage
export const clearAppState = () => {
  try {
    // Clear localStorage
    localStorage.clear();
    
    // Clear sessionStorage  
    sessionStorage.clear();
    
    // Clear any cached profile data
    window.dispatchEvent(new CustomEvent('clearUserData'));
  } catch (err) {
    console.warn('Error clearing app state:', err);
  }
};

export const handleLogout = async (): Promise<void> => {
  try {
    // Clear app state first
    clearAppState();
    
    // Sign out from Firebase
    await signOut(auth);
  } catch (error: any) {
    // Force clear state even on error
    clearAppState();
    
    // Force page reload as fallback
    window.location.href = '/login';
    
    throw new Error('Failed to log out properly. Please refresh the page and try again.');
  }
};