import React from 'react';
import { LogOut } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function LogoutButton({ collapsed = false }: { collapsed?: boolean }) {
  const { logOut } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logOut();
      navigate('/login');
    } catch (err) {
      console.error('Logout error:', err);
    }
  };

  return (
    <button
      onClick={handleLogout}
      className={`flex items-center gap-3 px-3 py-2 text-gray-700 hover:bg-gray-50 rounded-lg w-full ${
        collapsed ? 'justify-center' : ''
      }`}
    >
      <LogOut className="h-5 w-5" />
      {!collapsed && <span className="font-medium">Sign Out</span>}
    </button>
  );
}